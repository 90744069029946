export class CameraManager{  

    constructor(appManager){
        this.appManager = appManager;
        this.engine = null;
        this.camera = null;
        this.originalFov;
        this.keysDirectionAmount = [];
        this.animBlur, this.kernel, this.postProcess0;
        this.easingFunction = new BABYLON.SineEase();

        this.camera = this.createUniversalCamera();
        this.originalFov = this.camera.fov;

        var scene = this.appManager.scene;
        scene.onPrePointerObservable.add(function(pointerInfo) {
            var event = pointerInfo.event;
            var delta = 0;
            if (event.wheelDelta) {
                delta = event.wheelDelta;
            }
            else if (event.detail) {
                delta = -event.detail;
            }
            if (delta) {
                if(scene.activeCamera.fov < 1.7 && delta < 0)
                    scene.activeCamera.fov -= delta/1000;
                else if(delta > 0 && scene.activeCamera.fov > 0.7)
                    scene.activeCamera.fov -= delta/1000;
            }
        }, BABYLON.PointerEventTypes.POINTERWHEEL, false);
        //scene.activeCamera.fov = 1.2;
        
        var current_cam_pos = null
        var isMoving = false
        var self = this
        scene.registerBeforeRender(function update(){
            if (isMoving == false && current_cam_pos !== self.camera.rotation._x+self.camera.rotation._y) {
                isMoving = true

                self.appManager.spaceManager.currentSpace.setWidgetSizes(true)
            }
            else if(isMoving && current_cam_pos === self.camera.rotation._x+self.camera.rotation._y){
                isMoving = false
                self.appManager.spaceManager.currentSpace.setWidgetSizes(false)
            }
            current_cam_pos = self.camera.rotation._x+self.camera.rotation._y
        });

        document.getElementById("zoomOut").addEventListener("click", () => {
            if(scene.activeCamera.fov < 1.6)
                scene.activeCamera.fov +=  0.2;
        });
        document.getElementById("zoomIn").addEventListener("click", () => {
            if(scene.activeCamera.fov > 0.8)
                scene.activeCamera.fov -=  0.2;
        });
    }

    createUniversalCamera(){
        let camera = new BABYLON.FreeCamera("UniversalCamera", new BABYLON.Vector3(0.01, 0, 0), this.appManager.scene);
        camera.inputs.removeByType("FreeCameraKeyboardMoveInput")
        camera.setTarget(new BABYLON.Vector3(5, 0, 0));
        camera.attachControl(document.getElementById("renderCanvas"), true);
        camera.inputs.remove(camera.inputs.attached.mousewheel);
        camera.fov = 1.1;
        camera.ellipsoid = new BABYLON.Vector3(1, 1, 1);
        camera.invertRotation = true;  
        camera.noRotationConstraint = false
        camera.useNaturalPinchZoom = true;
        camera.inverseRotationSpeed = 1;

        var limitRotX = Math.PI*.28;
        this.appManager.scene.registerBeforeRender(function(){
            if(camera.rotation.x > limitRotX){
                camera.rotation.x = limitRotX;
            }else if(camera.rotation.x < -limitRotX){
                camera.rotation.x = - limitRotX;
            }
        });

        return camera;
    }

    createArcCamera() {
        let camera = new BABYLON.ArcRotateCamera("camera", -6, 1.8, -3, new BABYLON.Vector3(0,0,0), this.appManager.scene);
        camera.minZ = 0.01;
        camera.allowUpsideDown = false;
        camera.wheelPrecision = 150;
        camera.pinchDeltaPercentage = 0.0001;
        camera.useNaturalPinchZoom = false;
        camera.pinchPrecision = 0.0001;
        camera.panningSensibility = 0;
        camera.attachControl(document.getElementById("renderCanvas"), true);
        camera.rebuildAnglesAndRadius();
        camera.radius = 3;
        camera.useAutoRotationBehavior = true;
        camera.autoRotationBehavior.idleRotationSpeed = 0.1;
        camera.autoRotationBehavior.idleRotationWaitTime = 10;
        camera.autoRotationBehavior.idleRotationSpinupTime = 10;
        camera.autoRotationBehavior.zoomStopsAnimation = false;
        camera.lowerRadiusLimit = 1;
        camera.upperRadiusLimit = 10;
        return camera;
    }

    switchToCamera(camera, closeCallback) {
        this.appManager.cameraManager.camera.detachControl();
        this.appManager.scene.activeCamera = camera;

        let hdrTexture = BABYLON.CubeTexture.CreateFromPrefilteredData("https://charliebehan.github.io/DromoneARDemo/environment.dds", this.appManager.scene);
        this.appManager.scene.environmentTexture = hdrTexture;
        hdrTexture.gammaSpace = false;

        const canvasWrap = document.getElementById("canvasWrap");
        let closeButton = document.createElement("button");
        closeButton.setAttribute("id", "close3Dbutton");
        closeButton.style.top = "5%";
        //closeButton.style.right = "5%";
        closeButton.textContent = "close";
        //closeButton.style.width = "100px";
        closeButton.style.height = "30px";
        closeButton.style.position = "absolute";
        closeButton.addEventListener("click", () => {
            this.appManager.scene.activeCamera = this.camera;
            this.appManager.cameraManager.camera.attachControl(document.getElementById("renderCanvas"), true);

            closeButton.remove();
            hdrTexture.dispose();
            camera.dispose();
            closeCallback(); 
        });

        canvasWrap.appendChild(closeButton); 
    }

    lookAtMesh(meshRef){
        var origTarget = new BABYLON.Vector3(this.camera.target.x, this.camera.target.y, this.camera.target.z);
        var anim = BABYLON.Animation.CreateAndStartAnimation(
            'lookAtAnim', 
            this.camera, 
            'target',
            120, 
            30, 
            origTarget, 
            meshRef.absolutePosition, 
            0, 
            this.easingFunction);
    }

    async lookAtMeshSlowly(meshRef){
        this.camera.detachControl(document.getElementById("renderCanvas"));
        var origTarget = new BABYLON.Vector3(this.camera.target.x, this.camera.target.y, this.camera.target.z);
        var finalTarget = meshRef.absolutePosition
        var anim = BABYLON.Animation.CreateAndStartAnimation(
            'lookAtAnim', 
            this.camera, 
            'target', 
            60, 
            40, 
            origTarget, 
            finalTarget, 
            0, 
            this.easingFunction
        );
        await anim.waitAsync();
        this.camera.attachControl(document.getElementById("renderCanvas"), true);
    }

    transitionOutEffect(){
        BABYLON.Animation.CreateAndStartAnimation("fov anim", this.camera, "fov", 120, 20, this.originalFov, 1.5, 0, this.easingFunction);

        this.kernel = 32.0;	
        this.postProcess0 = new BABYLON.BlurPostProcess(
            "Horizontal blur", 
            new BABYLON.Vector2(3.0, 0), 
            this.kernel, 
            1.0, 
            this.camera,
            BABYLON.Texture.BILINEAR_SAMPLINGMODE,
            0.5,
            this.appManager.engine,
            true
        ); 

        this.keysDirectionAmount = [];
        this.animBlur = new BABYLON.Animation("animDof", 'direction', 30, BABYLON.Animation.ANIMATIONTYPE_VECTOR2, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE);
        this.keysDirectionAmount.push({frame: 0, value: new BABYLON.Vector2(0, 0.0)});
        this.keysDirectionAmount.push({frame: 50, value: new BABYLON.Vector2(0, 2.0)});
        this.animBlur.setKeys(this.keysDirectionAmount);
        this.postProcess0.animations = [];
        this.postProcess0.animations.push(this.animBlur);
        this.appManager.scene.beginAnimation(this.postProcess0, 0, 50, false, 3);
    }

    transitionInEffect(){
        BABYLON.Animation.CreateAndStartAnimation("fov anim", this.camera, "fov", 120, 30, 1.5, this.originalFov, 0);

        this.appManager.scene.beginAnimation(this.postProcess0, 50, 1, false, 3, function () {
            this.target.dispose();
        });
        this.appManager.spaceManager.isLoadingSpace = false;
    }
}