//TODO: revise if events are necessary
//import { triggerAsyncId } from 'async_hooks';
import EventEmitter from 'events';

const sidebarID = "sidebar";
const sidebarCloseID = "sidebarCloseButton";
const sidebarPseudoID = "sidebarPseudo";
const sidebarHeaderID = "sidebarHeader";
const sidebarContentID = "sidebarContent";
const guidanceButtonID = "guidanceButton";
const jumpToButtonID = "jumpToButton";
const siderbarTabContainerID = "sidebarTabContainer"

const spaceCardID = "spaceCard";

const guidanceCardID = "guidanceCard";
const guidanceCardLabelID = "guidanceCardLabel";
const guidanceCancelButtonID = "guidanceCancelButton";
const carouselContainerID = "carousel";

const currentSpaceLabelID = "currentSpaceLabel";
const buttonContainerID = "buttonContainer";
const hamburgerButtonID = "hamburgerButton";

const helpButtonID = "helpButton";
const helpCloseButtonID = "helpCloseButton";
const helpScreenID = "helpScreen";

const zoomInID = "zoomIn";
const zoomOutID = "zoomOut";

const timelineButtonID = "timelineButton";
const timelineContainerID = "timeline-container"
const timelineID = "timeline";



export class HTMLElements{

    constructor(canvasWrap){

        this.events = new EventEmitter();

        this.canvasWrap = canvasWrap;
        this.canvas;
        this.modelContainer;
        this.model;
        this.modelContent;
        this.dropdownBtn;
        this.dropdownContent;

        //rewrite
        this.sidebar = this.getElementById(sidebarID);
        this.siderbarPseudo = this.getElementById(sidebarPseudoID);
        this.sidebarClose = this.getElementById(sidebarCloseID);
        this.guidanceButton = this.getElementById(guidanceButtonID);
        this.jumpToButton = this.getElementById(jumpToButtonID);
        this.sidebarTabContainer = document.getElementById(siderbarTabContainerID);
        this.sidebarHeader = this.getElementById(sidebarHeaderID);
        this.sidebarContent = this.getElementById(sidebarContentID);

        this.spaceCard = this.getElementById(spaceCardID);
        
        this.guidanceCard = this.getElementById(guidanceCardID);
        this.guidanceCardLabel = this.getElementById(guidanceCardLabelID);
        this.guidanceCancelButton = this.getElementById(guidanceCancelButtonID);
        this.carouselContainer = this.getElementById(carouselContainerID);

        this.currentSpaceLabel = this.getElementById(currentSpaceLabelID);
        this.buttonContainer = this.getElementById(buttonContainerID);
        
        this.hamburgerButton = this.getElementById(hamburgerButtonID);

        this.helpButton = this.getElementById(helpButtonID);
        this.helpCloseButton = this.getElementById(helpCloseButtonID);
        this.helpScreen = this.getElementById(helpScreenID);

        this.zoomIn = this.getElementById(zoomInID);
        this.zoomOut = this.getElementById(zoomOutID);

        this.timelineButton = this.getElementById(timelineButtonID);
        this.timelineContainer = this.getElementById(timelineContainerID);
        this.timeline = this.getElementById(timelineID);

        this.initialise();
    }

    initialise(){
        this.createCanvas();
        this.createModalContainer();
        this.createModal();

        //rewrite
        this.initialiseHamburgerButton();
        this.initialiseTabGroup(this.guidanceButton, this.jumpToButton);
        this.initialiseSidebarCloseButton();
        this.initialiseHelpButton();
    }

    getElementById(id) {
        let element = document.getElementById(id);
        this.addEventEmitter(element);
        return element;
    }

    addEventEmitter(element) {
        element.addEventListener('click', () => this.events.emit(element.id));
    }

    addEventListener(element, callback) {
        this.events.on(element.id, callback);
    }

    createCanvas(){
        this.canvas = document.createElement('canvas');

        this.canvas.id = "renderCanvas";
        this.canvas.style.width = "100%";
        this.canvas.style.height = "100%";
        this.canvas.style.zIndex = 0;
        this.canvas.style.position = "relative";
        this.canvasWrap.appendChild(this.canvas);
    }

    initialiseTabGroup() { 
        for(const tabButton of arguments) {
            this.initialiseTabButton(tabButton);
        }
    }

    initialiseTabButton(tabButton) {
        tabButton.addEventListener('click', () => {
            this.setTabButtonActive(tabButton);
        });
    }

    setTabButtonActive(tabButton) {

        for(const child of tabButton.parentNode.children) {
            this.unsetTabButtonActive(child)
        }

        tabButton.classList.add('mdc-tab--active');
        tabButton.getElementsByClassName('mdc-tab-indicator')[0].classList.add('mdc-tab-indicator--active');
    }

    unsetTabButtonActive(tabButton) {
        tabButton.classList.remove('mdc-tab--active');
        tabButton.getElementsByClassName('mdc-tab-indicator')[0].classList.remove('mdc-tab-indicator--active');
    }
    
    initialiseHamburgerButton() {
        if(this.hamburgerButton == null) 
            return;

        this.hamburgerButton.addEventListener("click", () => {
            this.setSidebarVisible(true, false, false);
        });
    }

    initialiseHelpButton() {
        if(this.helpButton == null || this.helpScreen == null) 
            return;

        this.helpButton.addEventListener("click", () => {
            this.setMainUIVisible(false);
            this.helpScreen.classList.add("show");
        });

        this.helpCloseButton.addEventListener("click", () => {
            this.setMainUIVisible(true);
            this.helpScreen.classList.remove("show");
        });
    }

    initialiseSidebarCloseButton() {
        
        if(this.sidebarClose == null)
            return;

        this.sidebarClose.addEventListener("click", () => {
            this.setSidebarVisible(false, false);
            this.resetSidebarContent(200);
        });
    }

    //TODO: revise implementation for this.
    setSidebarVisible(isVisible, clearContent = true, hideTabs = true) {

        if(isVisible) {
            this.sidebar.classList.add("open");
            this.siderbarPseudo.classList.add("open");
            this.hamburgerButton.classList.add('hide');

            if(hideTabs)
                this.sidebarTabContainer.classList.add('hide');
        }
        else {
            this.sidebar.classList.remove("open");
            this.siderbarPseudo.classList.remove("open");
            this.hamburgerButton.classList.remove('hide');

            if(clearContent) 
                this.resetSidebarContent();
        }
    }

    setVisible(element, isVisible) {
        isVisible ? element.classList.remove('hide') : element.classList.add('hide');
    }

    isSidebarOpen() {
        return this.sidebar.classList.contains("open");
    }

    resetSidebarContent(delay) {

        if(delay && delay > 0) {
            setTimeout(() => this.resetSidebarContent(), delay);
            return;
        }

        this.sidebarTabContainer.classList.remove('hide');
        this.setSidebarHeaderTo("");
        this.sidebarContent.innerHTML = "";
    }

    setSidebarHeaderTo(text) {
        this.sidebarHeader.innerHTML = text;
    }

    appendChildToSidebarContent(element) {
        this.sidebarContent.appendChild(element);
    }

    setMainUIVisible(isVisible) {
        if(isVisible) {
            this.buttonContainer.classList.remove('hide');
            this.helpButton.classList.remove('hide');
            this.zoomIn.classList.remove('hide');
            this.zoomOut.classList.remove('hide');
        }
        else {
            this.buttonContainer.classList.add("hide");
            this.helpButton.classList.add('hide');
            this.zoomIn.classList.add('hide');
            this.zoomOut.classList.add('hide');
        }
    }

    createCarouselImage(url) {
        let image = document.createElement('div');
        image.style.backgroundImage = "url(" + url + ")";
        image.classList.add('guidanceCarouselImage');
        return image;
    }

    createTimelineCarouselElement(url, title) {
        let container = document.createElement('div');
        container.classList.add('timelineCarouselElement');

        let p = document.createElement('p');
        p.innerHTML = title;

        let image = this.createCarouselImage(url);
        container.appendChild(image);
        container.appendChild(p);

        return container;
    }

    createTimelineListItem(title) {
        let li = document.createElement('li');
        li.classList.add('swiper-slide');
        let timelineButton = document.createElement('div');
        timelineButton.classList.add('timeline-button');
        let timelineText = document.createElement('div');
        timelineText.classList.add('timeline-text');
        timelineText.innerText = title;

        li.appendChild(timelineButton);
        li.appendChild(timelineText);

        return li;
    }


    
    //TODO: rewrite
    createModalContainer(){
        this.modelContainer = document.createElement('div');

        this.modelContainer.id = "modal-container";
        this.modelContainer.style.width = "100%";
        this.modelContainer.style.height = "100%";
        this.modelContainer.style.visibility = "hidden";
        this.modelContainer.style.position = "absolute";
        this.modelContainer.style.left = 0;
        this.modelContainer.style.top = 0;
        this.modelContainer.style.display = "flex";
        this.modelContainer.style.display = "-webkit-box";
        this.modelContainer.style.display = "-moz-box";
        this.modelContainer.style.display = "-ms-flexbox";
        this.modelContainer.style.display = "-webkit-flex";
        this.modelContainer.style.flexDirection = "column";
        this.modelContainer.style.alignItems = "center";
        this.modelContainer.style.justifyContent = "center";

        this.modelContainer.addEventListener("click", close);

        function close(){
            document.getElementById("modal-container").style.visibility = "hidden";
            document.getElementById("modal").style.visibility = "hidden";
            var elements = document.getElementsByClassName("modal-content");
            while(elements[0] != null)
                elements[0].remove();
        }


        this.canvasWrap.appendChild(this.modelContainer);
    }
    createModal(){
        this.model = document.createElement('div');

        this.model.id = "modal";
        this.model.style.width = "90%";
        this.model.style.maxWidth = "1100px";
        this.model.style.minHeight = "70%";
        this.model.style.maxHeight = "70%";
        this.model.style.overflow = "auto";
        this.model.style.borderRadius = "5px";
        this.model.style.backgroundColor = "rgba(0,0,0,0.6)";

        this.modelContainer.appendChild(this.model);
    }
    createModalContent(){
        this.modelContent = document.createElement('div');
        this.modelContent.classList.add('modal-content');
        this.model.appendChild(this.modelContent);
        return this.modelContent;
    }

    createDropdown(){
        this.dropdown = document.createElement('div');

        this.dropdown.id = "dropdown";
        this.dropdown.style.position = "absolute";
        this.dropdown.style.left = 0;
        this.dropdown.style.top = 0;

        this.canvasWrap.appendChild(this.dropdown);
        this.createDropdownButton(this.dropdown);
    }

    showDropdown(show) {
        if(this.dropdown) {
            this.dropdown.style.display = show ? "unset" : "none";
        }
    }

    createDropdownButton(dropdown){
        this.dropdownBtn = document.createElement('div');

        this.dropdownBtn.innerHTML = "Guide me to";
        this.dropdownBtn.id = "dropdownBtn";
        this.dropdownBtn.style.paddingLeft = "11px";
        this.dropdownBtn.style.borderBottom = "2px solid rgba(250,250,250,0.9)";
        this.dropdownBtn.style.fontSize = "clamp(13px, 3.3vw, 20px)";
        this.dropdownBtn.style.lineHeight = "clamp(20px, 4.5vw, 32px)";
        this.dropdownBtn.style.height = "clamp(20px, 4.5vw, 32px)";
        this.dropdownBtn.style.width = "clamp(85px, 22vw, 130px)"; 
        this.dropdownBtn.style.borderBottomRightRadius = "5px";
        this.dropdownBtn.style.backgroundColor = "rgba(250,250,250,0.95)";
        this.dropdownBtn.style.color = "black"

        this.dropdown.appendChild(this.dropdownBtn);
        this.createDropdownContent(dropdown);
        
    }

    createDropdownContent(dropdown){
        let dropdownContent = document.createElement('div');
        this.dropdownContent = dropdownContent;
        dropdownContent.id = "dropdownContent";
        dropdownContent.style.display = "none";
        dropdownContent.style.position = "absolute";
        dropdownContent.style.height = "clamp(20px, 5vw, 32px)";
        dropdownContent.style.width = "clamp(90px, 23vw, 135px)";
        dropdownContent.style.fontSize = "clamp(11px, 2vw, 14px)";
        dropdownContent.style.color = "black"
        
        dropdownContent.style.zIndex = 5;
        dropdown.appendChild(dropdownContent); 
        
        this.onHoverDropdown();
        this.onTouchCanvas();
    }

    onHoverDropdown(){
        var self = this;
        this.dropdownBtn.onclick = function(){
            self.dropdownContent.style.display = "block";
        };
    }
    onTouchCanvas(){
        var self = this;
        this.canvas.onclick = function(){
            self.dropdownContent.style.display = "none";
        };
    }
    
}