export class HomeScreen{  

    constructor(appManager){
        this.appManager = appManager;
        this.introVideoEl = document.getElementById("introVideo")
        this.introVideoController = document.getElementById("myVideo")
        this.startButtonEl = document.getElementById("startButton")
        this.introTextEl = document.getElementById("introText")
        this.homeScreenEl = document.getElementById("homeScreen")
        this.homeScreenLogoEl = document.getElementById("homeScreenLogo")

        this.initialise()
    }

    initialise(){
        this.introTextEl.innerHTML = this.appManager.parsedJSON.subtitle
        this.introVideoEl.src = this.appManager.parsedJSON.introVideo
        this.homeScreenLogoEl.src = this.appManager.parsedJSON.logoImage
        this.startButtonEl = document.getElementById("startButton")
        
        this.introVideoController.addEventListener('loadeddata', function() {
            let loadingScreen = document.getElementById("loading-screen")
            if(loadingScreen.style.visibility !== "hidden")
                loadingScreen.style.visibility = "hidden"
         }, false);
        

        this.startButtonEl.onclick = () => {
            this.introVideoController.style.visibility = "hidden"
            this.startButtonEl.style.visibility = "hidden"
            this.introTextEl.style.visibility = "hidden"
            this.homeScreenEl.style.visibility = "hidden"
        };

        
        this.introVideoController.load();
        this.introVideoController.play();
    }
}