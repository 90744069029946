//TODO: change to use extends widget
export class Connection{  

    constructor(parsedJSONConnection, appManager){
        this.appManager = appManager;
        this.connectedSpaceName = parsedJSONConnection.connectedSpace.title;
        this.displayText = parsedJSONConnection.displayText || null;
        this.focusThisConnection = parsedJSONConnection.focusOnThisConnection || false;
        this.isHallway = parsedJSONConnection.isHallway || false;
        this.arrowDirection = parsedJSONConnection.arrowDirection || "straight";
        this.rotationX = parsedJSONConnection.y;
        this.rotationY = parsedJSONConnection.x;
        this.widgetButton = null;
        this.widgetButtonMesh = null;
        this.widgetPivot = null;
    }

    createWidgetButton(){
        this.widgetButton = this.createButton();
        this.widgetButtonMesh = BABYLON.MeshBuilder.CreatePlane("connectionWidget", {width:1.2, height:1}, this.appManager.scene);

        let buttonADT = BABYLON.GUI.AdvancedDynamicTexture.CreateForMesh(this.widgetButtonMesh);
        buttonADT.addControl(this.widgetButton);

        this.setWidgetLocation();
        this.setWidgetEvents();

        if(this.focusThisConnection){
            this.appManager.cameraManager.lookAtMeshSlowly(this.widgetButtonMesh);
        }
    }

    createButton(){
        var button1 = BABYLON.GUI.Button.CreateSimpleButton("but1", "");
        var ellipse1 = new BABYLON.GUI.Ellipse();
        ellipse1.width = "350px";
        ellipse1.height = "350px";
        //ellipse1.color = this.appManager.uiElements.colours.colourFadedLight25//"white";
        ellipse1.thickness = 0;
        ellipse1.background = this.appManager.uiElements.colours.colourFaded50;
        var ellipse2 = new BABYLON.GUI.Ellipse();
        ellipse2.width = "500px";
        ellipse2.height = "500px";
        ellipse2.thickness = 22;
        ellipse2.background = this.appManager.uiElements.colours.colourFadedBlack25;
        //ellipse2.addControl(ellipse1);
        button1.addControl(ellipse2);
        button1.width = "1000px";
        button1.height = "1000px";
        button1.paddingBottomInPixels = 270;
        button1.paddingTopInPixels = 250;
        button1.paddingLeftInPixels = 250;
        button1.paddingRightInPixels = 250;
        button1.color = "transparent"
        button1.clipChildren = false;
        button1.clipContent = false;

        //button1.addControl(this.createText())
        button1.addControl(this.createArrow()); 
        button1.pointerDownAnimation = null;
        button1.pointerUpAnimation = null;

        return button1;
    }

    createText(){
        if(this.displayText != null)
            var text = new BABYLON.GUI.TextBlock("text",this.displayText);
        else
            var text = new BABYLON.GUI.TextBlock("text","Go to "+this.connectedSpaceName);
        text.fontSizeInPixels = 140;
        text.color = "White";
        text.clipContent = false;
        text.scaleX = 0.7;
        text.shadowBlur = 50;
        text.textWrapping = BABYLON.GUI.TextWrapping.WordWrap;
        text.resizeToFit = true;

        return text;
    }

    createArrow(){	
        var imge = new BABYLON.GUI.Image("icon", "./textures/icons/arrow.svg");
        imge.width = "400px";
        imge.height = "400px";
        if(this.arrowDirection == "left"){
            /* imge.scaleY = 0.5;
            imge.leftInPixels = -340;
            imge.topInPixels = -100;
            imge.rotation = 5; */
        }else if(this.arrowDirection == "right"){
            /* imge.scaleY = 0.5;
            imge.leftInPixels = 340;
            imge.topInPixels = -100;
            imge.rotation = 1.3; */
        }else{
            /* imge.topInPixels = -350;
            imge.scaleY = 0.5; */
        }
       // imge.shadowBlur = 15;

        return imge
    }

    setWidgetLocation(){

        let widgetLocation = new BABYLON.TransformNode("widgetLocation");

        widgetLocation.position = new BABYLON.Vector3(0, 0, 0);
        widgetLocation.rotation.x += Math.PI * (this.rotationX * 0.1);
        widgetLocation.rotation.y += Math.PI * (this.rotationY * 0.1);

        this.widgetButtonMesh.position.z = 8;
        this.widgetButtonMesh.parent = widgetLocation;
    }

    setWidgetEvents(){
        this.widgetButton.pointerEnterAnimation = () => this.animateWidgetScaleTo(1.5);
        this.widgetButton.pointerOutAnimation = () => this.animateWidgetScaleTo(1);
        this.widgetButton.onPointerClickObservable.add(() => this.appManager.spaceManager.updateSpaceByName(this.connectedSpaceName));
    }

    animateWidgetScaleTo(scale) {
        let ease = new BABYLON.CubicEase();
        ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT);

        BABYLON.Animation.CreateAndStartAnimation('ConnectionScaleX', this.widgetButtonMesh, 'scaling.x', 60, 10, this.widgetButtonMesh.scaling.x, scale, 0, ease);
        BABYLON.Animation.CreateAndStartAnimation('ConnectionScaleY', this.widgetButtonMesh, 'scaling.y', 60, 10, this.widgetButtonMesh.scaling.y, scale, 0, ease);
    }

    setWidgetScale(value){
        if(value == "moving")
            this.widgetButtonMesh.scaling = new BABYLON.Vector3(0.7,0.7,0.7);
        else if(value == "hover" && this.widgetButtonMesh.scaling.x != 0.7)
            this.widgetButtonMesh.scaling = new BABYLON.Vector3(1.5,1.5,1.5);
        else if(value == "unhover" && this.widgetButtonMesh.scaling.x != 0.7)
            this.widgetButtonMesh.scaling = new BABYLON.Vector3(1,1,1);
        else if(value != "moving" && value != "hover" && value != "unhover")
            this.widgetButtonMesh.scaling = new BABYLON.Vector3(1,1,1);
    }

    dispose(){
        this.widgetButton.onPointerClickObservable.clear();
        this.widgetButton.dispose();
        this.widgetButtonMesh.parent.dispose();
        this.widgetButtonMesh.dispose(true, true);
    }
}