export class UIElements{  

    constructor(appManager){
        this.appManager = appManager;
        this.colours = {
            "colourDark": "hsl(265, 100%, 57%)",
            "colourLight": "hsl(0, 0%, 95%)",
            "colourFadedLight25": "hsla(0, 0%, 95%, 0.2)",
            "colourHighlight": "hsl(265, 100%, 57%)",
            "colourMain": "hsl(265, 100%, 57%)",
            "colourSecondary": "hsl(265, 100%, 57%)",
            "colourFaded75": "hsl(265, 97%, 57%, 75%)",
            "colourFaded50": "hsla(265, 97%, 57%, 0.5)",
            "colourFaded25": "hsla(265, 97%, 57%, 0.25)",
            "colourFadedBlack25": "hsla(0, 0%, 0%, 0.4)"
        }
        this.icons = appManager.parsedJSON.icons;
        this.logoImage = appManager.parsedJSON.logoImage;
        this.loadingImage = appManager.parsedJSON.loadingImage;
        this.color = appManager.parsedJSON.colors.value;
        this.logoWebsiteURL = appManager.parsedJSON.logoWebsiteURL
        this.mapImage = appManager.parsedJSON.mapImage

        this.setBranding()
    }

    setBranding(){
        document.documentElement.style.setProperty('--mdc-theme-primary', this.color);
        document.getElementById("logoImage").src = this.logoImage
        document.getElementById("loadingImage").src = this.loadingImage
        document.getElementById("imr-logo").href = this.logoWebsiteURL
    }
}