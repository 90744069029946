
export class PanelComponent{  

    constructor(type, content, htmlElements){
        this.type = type;
        this.content = content;
        this.htmlElements = htmlElements;
        this.div = null;

        this.createComponent();
    }

    createComponent(){
        //const currentDiv = document.getElementById("modal");
        this.div = this.htmlElements.createModalContent();
        //currentDiv.appendChild(this.div);
        this.htmlElements.appendChildToSidebarContent(this.div);
        this.loadContent();
    }

    loadContent(){
        switch (this.type) {
            case "video":
                this.setUpVideo(); break;
            case "form":
                this.setUpForm(); break;
            case "photo":
                this.setUpImage(); break;
            case "textBlock":
                this.setUpText(); break;
            case "model":
                this.setUp3D(); break;
            case "audio":
                this.setUpAudio(); break;
            /* case "website":
                this.setUpText(); break; */
            case "livedata":
                this.setUpLiveData(); break;
            case "website":
                this.setUpIFrame(); break;
        }
    }

    animateComponentLoad(index) {
        this.div.classList.add('animateLoad');
        setTimeout(() => { this.div.classList.add('loaded') }, 50 * index);
    }

    setUpVideo(){
        var ifrm = document.createElement("iframe");
        ifrm.setAttribute("src", this.content.videoURL);
        ifrm.style.width = "100%";
        ifrm.style.height = "calc(100px + 25vw)";
        ifrm.style.border = "none";

        this.div.appendChild(ifrm);
        //this.div.style.flexGrow = 2;
    }
    setUpForm(){
        var ifrm = document.createElement("iframe");
        ifrm.setAttribute("src", this.content.formURL);
        ifrm.style.width = "100%";
        ifrm.style.height = "100%";
        ifrm.style.border = "none";

        this.div.appendChild(ifrm);
        this.div.style.flexGrow = 2;
    }

    setUpImage(){
        var img = document.createElement("img");
        img.setAttribute("src", this.content.imageURL);
        img.style.height = "auto";
        img.style.width = "100%";
        img.style.border = "none";

        this.div.appendChild(img);
        //this.div.style.flexGrow = 2;
    }

    setUpAudio(){
        var audio = document.createElement("AUDIO");
        audio.setAttribute("src", this.content);
        audio.setAttribute("controls", "controls");
        audio.style.width = "100%";
        audio.style.height = "5vw";
        audio.style.border = "none";
        this.div.appendChild(audio);
    }

    setUpText(){
        var title, rest;

        var divTitle = document.createElement("h3");
        divTitle.appendChild(document.createTextNode(this.content.title));
        this.div.appendChild(divTitle);

        if(this.content.textBlock){
            var divText = document.createElement("p");
            divText.appendChild(document.createTextNode(this.content.textBlock));
            this.div.appendChild(divText);
        }
        this.div.style.flexShrink = 2;
    }

    setUpLiveData(){
        var content2 = null;
        var newContent;
        fetch(this.content)
            .then(response => response.json())
            .then(json => content2 = "Current temp: " + json.current.temp_c)
            .then(content2 => newContent = document.createTextNode(content2))
            .then(newContent => this.div.appendChild(newContent))
    }

    setUpIFrame() {
        let iframe = document.createElement("iframe");
        iframe.src = this.content.websiteURL//"https://imr.ie/pages/doosan-puma-2600sy-ii-lathe/";
        iframe.classList.add("widgetInfoIFrame");
        this.div.classList.add("modal-content-IFrame");
        this.div.appendChild(iframe);
        this.div.style.height = '100%';
    }
}