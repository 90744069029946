import { PanelComponent } from "./panelComponent";

export class PanelGroup{  

    constructor(widgetTitle, widgetGroup, content, type, appManager, isGroup){
        this.appManager = appManager;
        this.htmlElements = appManager.htmlElements;
        this.type = type;
        this.widgetTitle = widgetTitle;
        this.widgetGroup = null;
        if(isGroup)
            this.widgetGroup = content;
        else
            this.content = content;
        this.panels = [];
        this.myFunction = this.hideModal;

        this.createPanel();
    }

    createPanel(){
        //var modal = document.getElementById("modal");
        //var modalBackground = document.getElementById("modal-container");



        //modal.style.visibility = "visible";
        //modalBackground.style.visibility = "visible";

        this.htmlElements.setSidebarVisible(false);

        this.fillPanel();
        this.htmlElements.setSidebarHeaderTo(this.widgetTitle);
    }

    fillPanel(){
        var self = this;
        if(this.widgetGroup != null)
            //this.widgetGroup.forEach(element => addComponent(element));
            {
                let index = 0;
                for(const widget of this.widgetGroup) {
                    addComponent(widget, index);
                    index++;
                }
            }
        else  
            new PanelComponent(this.type, this.content[0],this.htmlElements);
        
        function addComponent(element, index) {
            let component = new PanelComponent(element._type, element,self.htmlElements);
            component.animateComponentLoad(index);
        }
        
        this.htmlElements.setSidebarVisible(true);
    }
}