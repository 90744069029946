import { CameraManager } from "./cameraManager";
import { UIElements } from "../Objects/uiElements";
import { HomeScreen } from "../Objects/homeScreen";
import { SpaceManager } from "./spaceManager";
import { PathfindingManager } from "./pathfindingManager";

export class AppManager{  

    constructor(scene, data, htmlElements){
        console.log(data)
        this.parsedJSON = data;
        this.scene = scene;
        this.htmlElements = htmlElements;
        
        this.cameraManager = new CameraManager(this);
        this.homeScreen = new HomeScreen(this);
        this.uiElements = new UIElements(this);
        this.spaceManager = new SpaceManager(this);
        this.pathfindingManager = new PathfindingManager(this);
        this.arManager;
        this.is3DModelRendered = false;
        this.timerNewSpace = null;
        
        this.spaceManager.currentSpace.createDome();

        //Setting default hamburger behaviour here
        // TODO: review how this default behaviour is set
        htmlElements.addEventListener(htmlElements.hamburgerButton, () => {
            htmlElements.setTabButtonActive(htmlElements.jumpToButton);
            this.spaceManager.setupJumpToSidebar();
        });
    }
}